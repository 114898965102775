export default class Modals {
  constructor() {
    this.triggers = document.querySelectorAll('[data-modal]')
    this.active = []

    if (!this.triggers) return

    this.initHandlers()
    this.initTriggers()
  }

  initHandlers() {
    const close = (e) => {
      const valid =
        e.key == 'Escape' ||
        e.target.closest('.is-exit') ||
        e.target.classList.contains('modal-background')

      if (valid && this.active.length) {
        const active = this.active.pop()
        active.classList.remove('is-active')
        active.removeEventListener('click', this.closeHandler)

        if (!this.active.length) {
          document.documentElement.classList.remove('is-clipped')
          document.removeEventListener('keyup', this.closeHandler)
        }
      }
    }

    this.closeHandler = close.bind(this)
  }

  initTriggers() {
    this.triggers.forEach((e) => {
      e.addEventListener('click', () => {
        const modal = document.querySelector(
          '.modal[data-id=' + e.dataset.modal + ']'
        )

        if (modal) {
          this.active.push(modal)

          if (this.active.length == 1) {
            document.documentElement.classList.add('is-clipped')
            document.addEventListener('keyup', this.closeHandler)
          }

          modal.classList.add('is-active')
          modal.addEventListener('click', this.closeHandler)

          const inputElement = modal.querySelector('input')
          if (inputElement) inputElement.focus()
        }
      })
    })
  }
}

class Api {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      this.csrf = document.getElementsByName('csrfmiddlewaretoken')[0]?.value
      this.language = document.documentElement.lang
      this.loader = document.querySelector('#loader')
      this.base = '/' + this.language + '/api/'
    })
  }

  request(path, opts) {
    return fetch(path, opts).then((response) => {
      if (response.ok) return response

      return Promise.reject(response)
    })
  }

  post(path, opts, base) {
    return this.request((base ? base : this.base) + path, {
      ...opts,
      method: 'POST',
      headers: {
        'X-CSRFToken': this.csrf,
        'Content-Type': 'application/json',
      },
    })
  }

  patch(path, opts) {
    return this.request(this.base + path, {
      ...opts,
      method: 'PATCH',
      headers: {
        'X-CSRFToken': this.csrf,
        'Content-Type': 'application/json',
      },
    })
  }

  delete(path, opts) {
    return this.request(this.base + path, {
      ...opts,
      method: 'DELETE',
      headers: {
        'X-CSRFToken': this.csrf,
      },
    })
  }

  get(path, opts) {
    return this.request(this.base + path, opts).then((response) =>
      response.json()
    )
  }

  // Misc.
  toggleFollow(type, id, favId) {
    const path =
      'account/' +
      (type === 'show' ? 'favorite-shows' : 'favorite-actors') +
      '/'

    return favId
      ? this.delete(path + favId + '/')
      : this.post(path, {
          body: JSON.stringify({ [type]: parseInt(id) }),
        }).then((response) => response.json())
  }

  getNewsPage(query) {
    return this.get('news/?' + query)
  }

  search(query) {
    return this.get('search/?' + query)
  }

  // Library
  getBooks(params) {
    return this.get('biblioteca/titluri/?' + new URLSearchParams(params))
  }

  postReservation(params) {
    return this.post('biblioteca/rezerva/', { body: JSON.stringify(params) })
  }

  getReservations() {
    return this.get('biblioteca/rezerva/')
  }

  deleteReservation(id) {
    return this.delete('biblioteca/rezerva/' + id + '/')
  }
}

export default new Api()

export default class Notification {
  constructor() {
    const notifications = document.querySelectorAll(
      '[data-component="notification"]'
    )

    notifications.forEach((element) => {
      const close = element.querySelector('[data-close]')
      close.addEventListener('click', (e) => {
        element.remove()
      })
    })
  }
}

import 'iconify-icon'

import Accordion from './components/accordion'
import ButtonFollow from './components/button-follow'
import ButtonLoadMore from './components/button-load-more'
import Carousel from './components/carousel'
import Modals from './components/modals'
import NewsIndex from './components/news-index'
import Notification from './components/notification'
import Program from './components/program'
import Search from './components/search'

import GLightbox from 'glightbox'
import { FreeMode } from 'swiper/modules'

import '@/scss/base.scss'

class APP {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      console.log('[TGST] Base online')

      const modals = new Modals()
      const accordion = new Accordion()
      const buttonFollow = new ButtonFollow()
      const buttonLoadMore = new ButtonLoadMore()
      const program = new Program()
      const newsIndex = new NewsIndex()
      const notification = new Notification()
      const search = new Search()

      this.initCarousels()

      const lightboxHTML = `
        <div id="glightbox-body" class="glightbox-container">
          <div class="gloader visible"></div>
          <div class="goverlay"></div>
          <div class="gcontainer">
            <div id="glightbox-slider" class="gslider"></div>
            <button class="gnext gbtn" tabindex="0" aria-label="Next" data-customattribute="example"><span class="icon"><iconify-icon icon="f7:chevron-compact-right"></iconify-icon></span></button>
            <button class="gprev gbtn" tabindex="1" aria-label="Previous"><span class="icon"><iconify-icon icon="f7:chevron-compact-left"></iconify-icon></span></button>
            <button class="gclose gbtn" tabindex="2" aria-label="Close"><span class="icon"><iconify-icon icon="lucide:x" class=""></iconify-icon></span></button>
          </div>
        </div>`

      const lightbox = GLightbox({
        touchNavigation: true,
        loop: true,
        lightboxHTML,
      })
    })
  }

  initCarousels() {
    this.carousels = {
      featured: new Carousel('.swiper.is-featured', {
        effect: 'fade',
        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true,
        },
      }),
      lists: new Carousel('.swiper.is-list.is-default', {
        freeMode: true,
        edgeSwipeDetection: 'prevent',
        slidesPerView: 1.1,
        breakpoints: {
          768: {
            slidesPerView: 2.2,
            // pagination: false,
          },
          1080: { slidesPerView: 3.3 },
        },
      }),
      actors: new Carousel('.swiper.is-actors', {
        freeMode: true,
        edgeSwipeDetection: 'prevent',
        slidesPerView: 1.7,
        breakpoints: {
          768: { slidesPerView: 3.4 },
          1080: { slidesPerView: 5.3 },
        },
      }),

      gallery: new Carousel('.swiper.is-gallery', {
        freeMode: true,
        edgeSwipeDetection: 'prevent',
        slidesPerView: 'auto',
        pagination: {
          el: '.swiper-fraction',
          type: 'fraction',
        },
      }),

      review: new Carousel('.swiper.is-review', {
        edgeSwipeDetection: 'prevent',
        slidesPerView: 'auto',
        centeredSlides: true,
      }),
    }
  }
}

export default new APP()
